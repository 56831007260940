export default {
  settings_list: {
    table: {
      id: 'ID',
      name: 'Name',
      active: 'Active',
      frequency: 'Frequency',
      startDate: 'Start Date',
      lastRun: 'Last Run',
      refid: 'Refid',
      select_file: 'Select File',
      aviaConfigGroup: {
        name: 'Config Group'
      }
    },
    form: {
      fields: {
        name: 'Name',
        active: 'Active',
        refid: 'Refid',
        routes: 'Routes',
        radarAviaConfigGroupId: 'Config Group',
        daysBeforeDepartures: 'Days Before Departure',
        daysAfterDepartures: 'Days After Departure',
        frequency: 'Frequency',
        currency: 'Currency',
        startDate: 'Start at',
        fixedAviaConfigs: 'Fixed configs'
      }    
    },
    action: {
      run: 'Run worker'
    }
  }
}