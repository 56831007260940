<template lang="pug">
  table(v-if="booking")
    thead
      tr
        th
        el-tooltip(v-for="fieldName in fieldsInTable" placement="top" :key="fieldName")
          div(slot="content" v-html="tooltipContentFor(fieldName)")
          th(v-html="fields[fieldName].title")
    tbody(@mouseover="hoverIdx = $event.target.cellIndex" @mouseleave="hoverIdx = undefined")
      tr(v-for="currency in priceCalculation.currencies" :key="currency")
        td(:class="{ hover: hoverIdx === 0, bold: currency == priceCalculation.payedCurrency }") {{ currency }}
        td(
          v-for="(fieldName, i) in fieldsInTable"
          :class="{ hover: hoverIdx === i + 1, bold: currency == priceCalculation.payedCurrency, marked: ['netto', 'profitMarkups'].includes(fieldName) }"
          :key="fieldName"
        )
          template {{ _.find(priceCalculation[fieldName], { currency }).amount }}
</template>

<script>
export default {
  props: {
    booking: null
  },

  data() {
    return {
      hoverIdx: undefined,
      fields: {
        netto: {
          title: 'Netto',
          tooltip: 'Netto from gds'
        },
        nettoCorrection: {
          title: 'Netto<br/>correction',
          tooltip: 'Difference between search and booking'
        },
        profitMarkups: {
          title: 'Profit<br/>markups',
          tooltip: `Dynamic<sup>1</sup> + Markups<sup>2</sup> + Auction<sup>3</sup> + FF comm<sup>4</sup><br/><br/>
                   <sup>1</sup> dynamic from price_details<br/>
                   <sup>2</sup> rl[0] + df.rl[0] from price_details<br/>
                   <sup>3</sup> rl[5] + df.rl[5] from price_details<br/>
                   <sup>4</sup> rl[7] + df.rl[7] from price_details`
        },
        paymentMarkups: {
          title: 'Payment<br/>markups',
          tooltip: `Discount - Promocode + Payment commission<sup>1</sup> + Transaction commission<sup>2</sup><br/><br/>
                   <sup>1</sup> from pricer table<br/>
                   <sup>2</sup> if present service pack or transaction commission`
        },
        surchargeFlight: {
          title: 'Surcharge<br/>flight',
          tooltip: 'Mco surcharges with reasons: tarif_change, free_seats, chd_tarif, client_surcharge, pay_to_us, add_infant'
        },
        surchargeAddons: {
          title: 'Surcharge<br/>add-ons',
          tooltip: 'Mco surcharges with reasons: meal_surcharge, baggage_surcharge, seat_surcharge, surcharge_free_text, additional_services'
        },
        surchargePostsale: {
          title: 'Surcharge<br/>post sale',
          tooltip: 'Mco surcharges with reasons: pass_not_correct, docs_change'
        },
        paymentMethodMarkup: {
          title: 'Payment<br/>method markup',
          tooltip: 'Payment commisssion without service pack'
        },
        alternativeCurrencyMarkup: {
          title: 'Alternative<br/>currency markup',
          tooltip: `Service pack <sup>1</sup><br/><br/>
                    <sup>1</sup> from discount_info -> service_pack_cash_depot`
        },
        flightTotal: {
          title: 'Flight total',
          tooltip: 'Transaction total - Bonus - Promocode + Transaction commission + Surcharge flight'
        },
        upsale: {
          title: 'Upsale',
          tooltip: 'Non GDS additional services'
        },
        ancillary: {
          title: 'Ancillary',
          tooltip: 'GDS additional services'
        },
        insurance: {
          title: 'Insurance',
          tooltip: 'Insurance'
        },
        diffMarkup: {
          title: 'Diff markup',
          tooltip: 'Additional discount'
        },
        payedTotal: {
          title: 'Payed total',
          tooltip: 'Payment transaction'
        }
      },

      fieldsInTable: [
        'netto',
        'nettoCorrection',
        'profitMarkups',
        'paymentMarkups',
        'surchargeFlight',
        'surchargeAddons',
        'surchargePostsale',
        'paymentMethodMarkup',
        'alternativeCurrencyMarkup',
        'flightTotal',
        'upsale',
        'ancillary',
        'insurance',
        'diffMarkup',
        'payedTotal'
      ]
    }
  },

  computed: {
    priceCalculation() {
      return (this.booking || {}).priceCalculation || {}
    },
    fieldCalculationDetails() {
      let data = JSON.parse((this.priceCalculation || {}).fieldCalculationDetails || '{}')
      data = this._.reduce(data, (result, value, key) => { result[this._.camelCase(key)] = value; return result }, {})
      let result = {}
      this._.each(this.fieldsInTable, (key) => { result[this._.camelCase(key)] = data[key] })
      return result
    }
  },

  methods: {
    tooltipContentFor(fieldName) {
      const {
        fields: { [fieldName]: { tooltip } = {} } = {},
        fieldCalculationDetails: { [fieldName]: calcTooltip } = {}
      } = this

      return [tooltip, calcTooltip].filter(Boolean).join('<br /><br />')
    }
  }
}
</script>

<style lang="scss" scoped>
.marked {
  background-color: #ffefcf;
}

table {
  line-height: 1;
  min-width: 100%;
  color: #334154;
  border-collapse: collapse;

  thead {
    background-color: #f5f5f5;
  }

  th,
  td {
    padding: 5px 10px;
  }

  th {
    height: 50px;
    font-size: 13px;
    text-align: left;
  }

  td {
    height: 40px;
    font-size: 14px;
    line-height: 1.393;
    transition: background-color 0.15s ease;

    &.hover {
      background-color: #f5f5f5;
    }

    &:hover {
      background-color: #ececec;
    }
  }

  tr:not(:first-child) {
    border-top: 1px solid #ececec;
  }
}
</style>
