<template lang="pug">
  div
    el-dialog.avia-booking-passengers-dialog(
      title="Edit passenger information"
      :visible.sync="editPaxDialog.visible"
      v-loading="editPaxDialog.loading" 
      element-loading-text="Loading..."
      width="100%"
    )
      el-form(:model="editPaxDialog.form" :rules="editPaxDialog.rules" label-position="left" label-width="100px")
        el-row(:gutter="40")
          el-col(:xs="24" :sm="8")
            .avia-booking-passengers-dialog__title.bold Passenger
            el-form-item(label="First name" prop="firstName")
              el-input(v-model="editPaxDialog.form.firstName")
            el-form-item(label="Last name" prop="lastName")
              el-input(v-model="editPaxDialog.form.lastName")
            el-form-item(label="Patronymic" prop="patronymic")
              el-input(v-model="editPaxDialog.form.patronymic")
            el-form-item(label="Date of birth" prop="birthDay")
              el-date-picker(v-model="editPaxDialog.form.birthDay" type="date" placeholder="Pick a day" format="dd-MM-yyyy")
            el-form-item(label="Sex" prop="gender")
              el-select(v-model="editPaxDialog.form.gender")
                el-option(v-for="o in ['M', 'F', 'MI', 'FI']" :label="o" :value="o" :key="o")
          el-col(:xs="24" :sm="8")
            .avia-booking-passengers-dialog__title.bold Document
            el-form-item(label="Document" prop="passportNumber")
              el-input(v-model="editPaxDialog.form.passportNumber")
            el-form-item(label="Doc. exp. date" prop="passportTerm")
              el-date-picker(v-model="editPaxDialog.form.passportTerm" type="date" placeholder="Pick a day" format="dd-MM-yyyy")
            el-form-item(label="Nationality" prop="countryId")
              el-select(v-model="editPaxDialog.form.countryId")
                el-option(v-for="o in countryList" :label="`${o.name} (${o.code})`" :value="o.code" :key="o.code")
          el-col(:xs="24" :sm="8")
            .avia-booking-passengers-dialog__title.bold Fare and Final Cost
            el-form-item(label="Tariff")
              el-input(v-model="editPaxDialog.form.amounts.values.tarif")
            el-form-item(label="Equivalent")
              el-input(v-model="editPaxDialog.form.amounts.values.equiv")
            el-form-item(label="Taxes")
              el-input(v-model="editPaxDialog.form.amounts.values.taxes")
            el-form-item(label="Currency")
              el-input(v-model="editPaxDialog.form.amounts.currency")
            el-form-item(label="Bonus card" prop="bonusCard")
              el-input(v-model="editPaxDialog.form.bonusCard")
      div(slot="footer")
        el-button(type="primary" size="medium" plain @click="editPaxDialog.visible = undefined") Dont save and close
        el-button(type="primary" size="medium" @click="savePaxDialogForm()") Save changes

    table
      thead
        tr
          th(v-for="(head, h) in ['PTC', 'Last name', 'First name', 'Patronymic', 'Date of birth', 'Gender', 'Citizenship', 'Passport', 'Doc. exp. date', 'Bonus card', '']" :key="h") {{ head }}
      tbody(@mouseover="hoverIdx = $event.target.cellIndex" @mouseleave="hoverIdx = undefined")
        tr(v-for="(pax, i) in passengers" :key="i")
          td(:class="{ hover: hoverIdx === 0 }" width="88").nowrap {{ pax.ageType }} {{ (['CHD', 'INF'].includes(pax.ageType) && calculateAge(pax)) || '' }}
          td(:class="{ hover: hoverIdx === 1 }") {{ pax.lastName }}
          td(:class="{ hover: hoverIdx === 2 }") {{ pax.firstName }}
          td(:class="{ hover: hoverIdx === 3 }") {{ pax.patronymic || '-' }}
          td(:class="{ hover: hoverIdx === 4 }").nowrap {{ formatDate(pax.birthDay, 'DD-MM-YYYY') }}
          td(:class="{ hover: hoverIdx === 5 }" width="65") {{ pax.gender }}
          td(:class="{ hover: hoverIdx === 6 }") {{ getDictionaryBy('countries', pax.countryId) }} ({{ pax.countryId }})
          td(:class="{ hover: hoverIdx === 7 }") {{ pax.passportNumber }}
          td(:class="{ hover: hoverIdx === 8 }").nowrap {{ formatDate(pax.passportTerm, 'DD-MM-YYYY') }}
          td(:class="{ hover: hoverIdx === 9 }") {{ pax.bonusCard || '-' }}
          td(style="text-align: center")
            el-button(type="primary" icon="el-icon-edit" mini @click="showEditPaxDialog(pax)")
</template>

<script>
// import BaseTable from '@crud_view/table'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],

  props: {
    booking: null,
    reloadBooking: null
  },

  data() {
    return {
      hoverIdx: undefined,
      editPaxDialog: {
        visible: false,
        loading: false,
        form: { amounts: { values: {} } },
        rules: {}
      },
      countryList: []
    }
  },

  computed: {
    passengers() {
      return ((this.booking || {}).passengers || []).map((pax) => ({
        ...pax,
        birthDay: Number(pax.birthDay) * 1000,
        passportTerm: Number(pax.passportTerm) * 1000
      }))
    },

    dictionary() {
      return JSON.parse((this.booking || {}).dictionary || '{}')
    },

    firstSegment() {
      return ((this.booking || {}).locations || [])[0]
    }
  },

  methods: {
    getDictionaryBy(key, code) {
      return this.dictionary[key][code] || code
    },

    formatDate(date, formatStr) {
      return (date && this.$moment(date).format(formatStr)) || ''
    },

    calculateAge(pax) {
      const birthday = this.$moment(pax.birthDay)
      const departureDate = this.$moment(`${this.firstSegment.arrivalDate} ${this.firstSegment.arrivalTime}`, 'DD.MM.YYYY h:mm')
      const diff = departureDate.diff(birthday, 'month')

      const years = Math.floor(diff / 12)
      const month = diff % 12

      return `${years}y${month}m`
    },

    showEditPaxDialog(pax) {
      this.editPaxDialog.visible = true
      this.editPaxDialog.form = {
        id: pax.id,
        gender: pax.gender,
        birthDay: this.$moment(pax.birthDay),
        passportNumber: pax.passportNumber,
        passportTerm: this.$moment(pax.passportTerm),
        bonusCard: pax.bonusCard,
        countryId: pax.countryId,
        firstName: pax.firstName,
        lastName: pax.lastName,
        patronymic: pax.patronymic,
        amounts: { ...JSON.parse(pax.amounts || '{}') }
      }

      this.loadCountryList()
    },

    async loadCountryList() {
      if (this.countryList.length > 0) return this.countryList

      this.editPaxDialog.loading = true

      try {
        const res = await this.graphql('aviaBookingItemCountryList', {}, 'query')

        this.countryList = JSON.parse(res)
      } catch (e) {
        this.$message({ type: 'error', message: e })
      } finally {
        this.editPaxDialog.loading = undefined
      }
    },

    async savePaxDialogForm() {
      this.editPaxDialog.loading = true

      try {
        const query = {
          __args: {
            ...this.editPaxDialog.form,
            birthDay: this.editPaxDialog.form.birthDay.toString(),
            passportTerm: this.editPaxDialog.form.passportTerm.toString(),
            amounts: JSON.stringify(this.editPaxDialog.form.amounts)
          }
        }

        await this.graphql('aviaBookingItemSavePassenger', query, 'mutation')

        this.$notify({
          title: 'Success',
          message: 'Data successfully saved',
          duration: 2600
        })

        this.editPaxDialog.visible = false

        this.reloadBooking(this.booking.id)
      } catch (e) {
        this.$message({ type: 'error', message: e })
      } finally {
        this.editPaxDialog.loading = undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}

table {
  border-collapse: collapse;
  min-width: 100%;
  font-size: 14px;
  line-height: 1.2;
  color: #334154;

  thead {
    font-size: 13px;
    text-align: left;
    background-color: #f5f5f5;
  }

  tr:not(:first-child) {
    border-top: 1px solid #ececec;
  }

  th,
  td {
    height: 40px;
    padding: 5px 10px;
  }

  td {
    transition: background-color 0.15s ease;

    &.hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      background-color: #ffffff;
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }

  td:not(:last-child):hover {
    background-color: #ececec;
  }
}
</style>

<style lang="scss">
.avia-booking-passengers-dialog {
  &__title {
    font-size: 18px;
    color: #256ccc;
    margin-bottom: 10px;
  }

  .el-dialog {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;

    &__header,
    &__body,
    &__footer {
      padding-left: 30px;
      padding-right: 30px;
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 20px;
    }

    &__footer {
      padding-top: 30px;
    }
  }

  .el-form-item--mini {
    &.el-form-item {
      margin-bottom: 4px;
    }

    .el-form-item__content,
    .el-form-item__label,
    .el-input__inner {
      color: #334154;
      line-height: 28px;
      height: 28px;
    }

    .el-select,
    .el-date-editor {
      width: 100%;
    }
  }
}
</style>
