<template lang="pug">
  table(v-if="booking")
    tr(v-for="({ username, email, phone }, i) in userInfo" :key="i")
      td.upper {{ username }}
      td
        el-link(type="primary" :href="'mailto:'.concat(email)" target="_blank") {{ email }}
      td
        el-link(type="primary" :href="'tel:+'.concat(phone)" target="_blank") {{ phone }}
</template>

<script>
export default {
  props: {
    booking: null
  },

  computed: {
    userInfo() {
      const { user, additionalInfo } = this.booking || {}
      const { real_contacts: real = {} } = JSON.parse(additionalInfo || '{}')

      return [
        user,
        Object.values(real).length &&
          (real.email != user.email || real.phone != user.phone) &&
          real
      ].filter(Boolean)
    }
  }
}
</script>

<style lang="scss" scoped>
.upper {
  text-transform: uppercase;
}

.el-link {
  font-weight: normal;
}

table {
  min-width: 100%;
  font-size: 14px;
  line-height: 1.393;
  color: #334154;
  border-collapse: collapse;

  td {
    padding: 10px;
  }

  tr:not(:first-child) {
    border-top: 1px solid #ececec;
  }
}
</style>
