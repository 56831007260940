export default {
  bookings_list: {
    table: {
      // id: 'ID',
      reservationId: 'Locator / Ticket #',
      // bookingId: 'Ticket #',
      user: 'User',
      country: 'Country',
      route: 'Train / Route',
      totalCost: 'Cost / Depot',
      // depotId: 'Depot',
      createdAt: 'Created / Cancelled',
      expirationTime: 'Status / Expiration',
      cancelledAt: 'Cancelled',
      trafficSourceId: 'Traffic Source',
      paymentType: 'Payment Type',
      paymentDate: 'Payment Date',
      comment: 'Last Comment',
      trip_info: 'Trip Info',
      ticket: 'Ticket',
      session_id: 'Session ID',
      train: 'Train',
      route2: 'Route',
      departure: 'Departure',
      arrival: 'Arrival',
      carriage: 'Carriage',
      class: 'Class',
      type: 'Type',
      bedclothes: 'Bedclothes',
      yes: 'Yes',
      no: 'No',
      insurance: 'Insurance',
      locator: 'Locator',
      passenger_info: 'Passenger Info',
      salutation: 'Salutation',
      first_name: 'First Name',
      last_name: 'Last Name',
      patronymic: 'Patronymic',
      nationality: 'Nationality',
      doc_type: 'Doc Type',
      doc_number: 'Doc Number',
      birthday: 'Birthday',
      seat: 'Seat',
      adult: 'Adult',
      child: 'Child',
      student: 'Student',
      cost: 'Cost',
      refund_status: 'Refund Status',
      refund_amount: 'Refund Amount',
      refund_date: 'Refund Date',
      refund_commission: 'Refund Commission',
      booking_id: 'Booking ID',
      fiscal_id: 'Fiscal ID',
      services: 'Services',
      service: 'Service',
      baggage: 'Baggage',
      weight: 'Weight',
      vat: 'VAT',
      service_cost: 'Service Cost',
      uid: 'UID',
      reservation_id: 'Reservation ID',
      segments: 'Segments',
      train_number: 'Train Number',
      travel_time: 'Travel Time',
      upsales: 'Upsales',
      name: 'Name',
      currency: 'Currency',
      payments: 'Payments',
      acquiring: 'Acquiring',
      transaction_commission: 'Transaction Commission',
      scheme_service: 'Scheme Service',
      default_scheme_service: 'Default Scheme Service',
      default_service: 'Default Service',
      bonus: 'Bonus',
      promotion: 'Promotion',
      total_cost: 'Total Cost',
      full_cost: 'Full Cost',
      original_cost: 'Original Cost',
      date: 'Date',
      payer: 'Payer',
      payment_system: 'Payment System',
      transaction_id: 'Transaction ID',
      amount: 'Amount',
      system_id: 'System ID',
      status: 'Status',
      total: 'Total',
      user_id: 'User ID',
      card: 'Card',
      payment_transaction: 'Payment transaction',
      comments: 'Comments',
      refund_statuses: {
        0: 'PAID',
        1: 'REFUNDED',
        5: 'RETURNED_SEATS',
        6: 'BOARDING_PASS_ISSUED',
        18: 'REFUND_AWAIT',
        19: 'REFUND_QUEUED',
        20: 'REQUEST_IN_PROCESS',
        100: 'BEING_PAID',
        101: 'CONFIRMATION_FAILED'
      },
      segment_type: 'Segment type',
      car_number: 'Car number',
      seats: 'Seats',
      addl_info_json: 'Custom info',
      pricer_rules: 'Pricer rules'
    },
    panel: {
      transactions: 'Transactions',
      comments: 'Comments',
      change_status: 'Change Status',
      files: 'Custom',
      file: {
        download: 'Download File',
        regenerate: 'Regenerate File',
        removeParentId: 'Remove Parent ID',
        removeChildId: 'Remove Child ID',
        confirmBooking: 'Confirm Booking'
      }
    },
    form: {
      fields: {
        id: 'Booking ID',
        sessionId: 'Session ID',
        userId: 'User ID',
        status: 'Status',
        depotId: 'Depot'
      }
    },
    booking: {
      document_services: {
        1: 'Паста болоньєзе з базиліком',
        2: 'дріп кава',
        3: '2 чая',
        4: 'постільні речі',
        5: '1 напій',
        6: '2 напої',
        7: 'Локшина по-вегетаріанськи',
        8: 'Вода',
        9: 'кава',
        10: 'авторський чай'
      },
      baggage: {
        1: 'animal_birds',
        2: 'equipment',
        3: 'excess',
        4: 'personal item',
        5: 'cabin bag',
        6: 'hold bag'
      },
      carType: {
        1: 'Люкс',
        2: 'Купе',
        3: 'Плацкарт',
        4: 'Сидячий',
        5: 'Загальний',
        6: 'Мякий'
      }
    }
  }
}